import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { NgControl } from '@angular/forms';
import { ApiServiceInterface, UserServiceInterface, ValueAccessorBase } from '@hutsix/ngxh6';
import { KnownClientFormComponent } from './forms/known-client-form/known-client-form.component';
import { ToastrService } from 'ngx-toastr';
import { take } from 'rxjs/operators';

type FrequentFlyers = {
    [clientId: string]: number;
};

@Component({
    selector: 'known-clients',
    templateUrl: './known-clients.component.html',
    styleUrls: ['./known-clients.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
// export class KnownClientsComponent extends SfFormCollectionComponent implements Validator, OnChanges, OnInit {
export class KnownClientsComponent extends ValueAccessorBase implements OnChanges, OnInit {
    protected readonly knownClientFormComponent = KnownClientFormComponent;
    protected readonly Object = Object;

    public frequentFlyers: FrequentFlyers = {};
    public frequentFlyersInitialised: boolean = false;

    // Create dummy model so an 'on modelChange' can be triggered
    public model: any = {};
    public inputValue: any = [];

    constructor(
        @Inject('ApiService') private api: ApiServiceInterface,
        public cdRef: ChangeDetectorRef,
        public ngControl: NgControl,
        @Inject('UserService') public userService: UserServiceInterface,
        private toastr: ToastrService,
    ) {
        super(cdRef, ngControl);
    }

    ngOnInit() {
        super.ngOnInit();

        // Get frequent flyer status on load
        if (this.inputValue) {
            this.getFrequentFlyers();
            this.cdRef.detectChanges();
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        super.ngOnChanges(changes);
    }

    public delete(id?: string, index: any = null): void {
        if (!confirm('Are you sure?')) {
            return;
        }

        if (id) {
            this.inputValue = this.inputValue.filter(item => item.id !== id);
            this.onInputChange();
        }

        this.cdRef.detectChanges();
    }

    modelChange(value: any): void {
        if (!value?.knownClients) return;

        if (this.inputValue.find(kc => kc.id === value.knownClients.id)) {
            this.toastr.warning('That client has already been added.');
        } else {
            this.inputValue.push(value.knownClients);
            this.getFrequentFlyers();

            this.onInputChange();
        }
        this.cdRef.detectChanges();

        return;
    }

    public onSubmit(): void {
        this.model = { contact: null };
    }

    public initialiseFrequentFlyers(): void {
        if (!this.frequentFlyersInitialised) {
            this.getFrequentFlyers();
            this.frequentFlyersInitialised = true;
        }
    }

    getFrequentFlyers(): void {
        // Get list of known client Ids.
        const clientIds: [] = this.inputValue.map(obj => obj.id);

        if (clientIds.length) {
            this.api
                .get({ url: '/api/frequent-flyers', query: { clientIds: clientIds.join(',') }, displayErrors: false })
                .pipe(take(1))
                .subscribe(res => {
                    this.frequentFlyers = res.data.reduce((acc: FrequentFlyers, item: { clientId: string; engagementCount: number }) => {
                        acc[item.clientId] = item.engagementCount;
                        return acc;
                    }, {});
                    this.cdRef.detectChanges();
                });
        }
    }
}

<ng-container *ngIf="form.view; else loadingOrError">
    <form #formRef="ngForm" (ngSubmit)="submit()" *ngIf="form.view">
        <div class="d-flex flex-column flex-md-row">
            <div class="flex-grow-1">
                <sf-form
                    name="form"
                    (ngModelChange)="onModelChange($event)"
                    [view]="getView(form.view)"
                    [(ngModel)]="form.model"
                    [fields]="[{ field: 'knownClients' }]"
                ></sf-form>
            </div>
        </div>
    </form>
</ng-container>

<ng-template #loadingOrError>
    <ng-container *ngIf="form.error; else loading">
        <small class="text-danger">Error loading form</small>
    </ng-container>

    <ng-template #loading>
        <small class="text-muted">Loading...</small>
    </ng-template>
</ng-template>

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { InvolvedDefault } from '../../../../../../../_generated/api_interfaces';

@Component({
    selector: 'app-involved-summary',
    templateUrl: './involved-summary.component.html',
    styleUrls: ['./involved-summary.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvolvedSummaryComponent {
    @Input() involved: InvolvedDefault;

    public choices = [
        { choice: 'age0to5', label: '0 - 5' },
        { choice: 'age6to12', label: '6 - 12' },
        { choice: 'age13to17', label: '13 - 17' },
        { choice: 'age18to24', label: '18 - 24' },
        { choice: 'age25to35', label: '25 - 35' },
        { choice: 'age36to46', label: '36 - 46' },
        { choice: 'age47plus', label: '47+' },
    ];

    constructor() {}
}

import { animate, query, style, transition, trigger } from '@angular/animations';

export const smoothHeight = trigger('grow', [
    transition('void => *', []),
    transition(
        '* => void',
        [query(':self', [style({ overflow: 'hidden' })], { optional: true }), style({ height: '{{startHeight}}px' }), animate('.3s linear', style({ height: '0px' }))],
        { params: { startHeight: 0, endHeight: 0 } },
    ),
    transition(
        '* <=> *',
        [
            query(':self', [style({ overflow: 'hidden' })], { optional: true }),
            style({ height: '{{startHeight}}px' }),
            animate('{{timing}}ms linear', style({ height: '{{endHeight}}px' })),
        ],
        { params: { startHeight: 0, endHeight: 0, timing: 100 } },
    ),
]);

export const fadeInOut = trigger('fadeInOut', [
    transition('void => *', [style({ opacity: 0 }), animate(300, style({ opacity: 1 }))]),
    transition('* => void', [style({ position: 'absolute', width: '100%', opacity: 1 }), animate(300, style({ opacity: 0 }))]),
]);

export const fadeInOut2 = trigger('fadeInOut2', [
    transition('void => *', [style({ opacity: 0 }), animate(300, style({ opacity: 1 }))]),
    transition('* => void', [style({ width: '100%', opacity: 1 }), animate(300, style({ opacity: 0 }))]),
]);

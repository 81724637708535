import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { NgControl, Validator } from '@angular/forms';
import { ValueAccessorBase } from '@hutsix/ngxh6';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'sf-form-involved',
    templateUrl: './sf-form-involved.component.html',
    styleUrls: ['./sf-form-involved.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SfFormInvolvedComponent extends ValueAccessorBase implements OnChanges {
    public choices = [
        { choice: 'age0to5', label: '0 - 5' },
        { choice: 'age6to12', label: '6 - 12' },
        { choice: 'age13to17', label: '13 - 17' },
        { choice: 'age18to24', label: '18 - 24' },
        { choice: 'age25to35', label: '25 - 35' },
        { choice: 'age36to46', label: '36 - 46' },
        { choice: 'age47plus', label: '47+' },
    ];

    public inputValue: any = {};

    constructor(public cdRef: ChangeDetectorRef, public ngControl: NgControl) {
        super(cdRef, ngControl);
    }

    ngOnChanges(changes: SimpleChanges): void {
        // console.log(this.view.vars);

        super.ngOnChanges(changes);
    }

    minus(choice): void {
        console.log(this.inputValue);
        if (this.inputValue[choice]) this.inputValue[choice]--;
        if (!this.inputValue[choice]) this.inputValue[choice] = null;
        this.onInputChange();
    }

    add(choice): void {
        if (!this.inputValue[choice]) this.inputValue[choice] = 0;
        this.inputValue[choice]++;
        this.inputValue = JSON.parse(JSON.stringify(this.inputValue));
        this.onInputChange();
    }

    writeValue(value: any): void {
        if (value === undefined || value === null) value = {};
        super.writeValue(value);
    }
}

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from '@angular/core';
import { DefaultFormComponent, fadeInOut, fadeInOut2, FormServiceInterface, UserServiceInterface } from '@hutsix/ngxh6';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-known-client-form',
    templateUrl: './known-client-form.component.html',
    styleUrls: ['./known-client-form.component.scss'],
    animations: [fadeInOut, fadeInOut2],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KnownClientFormComponent extends DefaultFormComponent {
    constructor(
        @Inject('FormService') public formService: FormServiceInterface,
        @Inject('UserService') public userService: UserServiceInterface,
        public location: Location,
        public cdRef: ChangeDetectorRef,
        public toastr: ToastrService,
    ) {
        super(formService, userService, location, cdRef);
    }

    submit(): void {
        this.submitted.emit({ type: 'cache', data: this.form.model, timestamp: new Date().getTime() });
    }

    onModelChange($e): void {
        // const model = JSON.parse(JSON.stringify(this.form.model));
        // console.log('model', model);
        // console.log('inputValue', this.inputValue);

        // if (Array.isArray(model.staff) && !model.staff.map(staff => staff.id).includes(this.userService.user.id)) {
        //     this.toastr.warning('You must include yourself.');
        //     setTimeout(() => {
        //         model.staff.push(this.userService.user);
        //         this.form.model = model;
        //         this.cdRef.detectChanges();
        //         super.onModelChanged($e);
        //     }, 100);
        //
        //     return;
        // }

        super.onModelChanged($e);
    }

    getView(view: any) {
        // Update known client drop down to be singular
        view.children.knownClients.vars.multiple = false;

        return view;
    }
}

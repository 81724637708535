<div class="collection-container">
    <ngxh6-form-embed
        class="flex-grow-1 app-community-staff-select-form"
        [model]="model"
        (submitted)="onSubmit()"
        (modelChange)="modelChange($event)"
        [formName]="'engagement'"
        [component]="knownClientFormComponent"
    ></ngxh6-form-embed>

    <ng-container>
        <ng-container *ngIf="inputValue">
            {{ initialiseFrequentFlyers() }}
            <hr />
            <ng-container *ngFor="let item of Object.values(inputValue); let i = index">
                <div class="known-client">
                    <div class="d-flex align-items-center">
                        <ngxh6-offline-indicator [model]="item" class="me-2"></ngxh6-offline-indicator>
                        <ngxh6-usericon [user]="item" class="me-3"></ngxh6-usericon>
                        <p class="mb-0">
                            <a *ngIf="userService.isGranted('ROLE_PATROL_MANAGER')" [routerLink]="'/clients/' + item.id">
                                {{ item.firstName + ' ' + item.lastName }}

                                <ng-container *ngIf="item.archived"> (archived)</ng-container>
                            </a>

                            <ng-container *ngIf="!userService.isGranted('ROLE_PATROL_MANAGER')">
                                <ng-container *ngIf="frequentFlyers[item.id] && frequentFlyers[item.id] > 0">
                                    <span class="bg-warning mx-2 rounded-1 text-white p-2">
                                        <i class="fass fa-plane pe-1"></i>x {{ frequentFlyers[item.id] }}
                                    </span>
                                </ng-container>

                                {{ item.firstName + ' ' + item.lastName }}
                                <ng-container *ngIf="item.archived"> (archived)</ng-container>
                            </ng-container>

                            <span *ngIf="item?.__offline_create__" class="small text-muted">created offline</span>
                        </p>

                        <button type="button" class="ms-auto btn btn-danger collection-remove" (click)="delete(item.id, i)">
                            <i class="fa fa-trash"></i>
                        </button>
                    </div>
                </div>
                <hr />
            </ng-container>
        </ng-container>

        <small class="my-4 text-muted" *ngIf="!inputValue">There are no known clients for this engagement.</small>
    </ng-container>
</div>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SfFormDateComponent } from './components/forms/custom-fields/sf-form-date/sf-form-date.component';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ToastrModule } from 'ngx-toastr';
import { DROPZONE_CONFIG, DropzoneConfigInterface, DropzoneModule } from 'ngx-dropzone-wrapper';
import { MomentModule } from 'ngx-moment';
import { UserFormComponent } from './components/forms/custom-forms/user-form/user-form.component';
import { SfFormDatetimeComponent } from './components/forms/custom-fields/sf-form-datetime/sf-form-datetime.component';
import { SfFormTimeComponent } from './components/forms/custom-fields/sf-form-time/sf-form-time.component';
import { Ngxh6Module, SfFormModule } from '@hutsix/ngxh6';
import { NavbarComponent } from './components/ui/navbar/navbar.component';
import { LoginComponent } from './routes/user/login/login.component';
import { DashboardComponent } from './routes/dashboard/dashboard.component';
import { ReloadPageComponent } from './routes/reload-page/reload-page.component';
import { SfFormSelectImagegridComponent } from './components/forms/custom-fields/sf-form-select-imagegrid/sf-form-select-imagegrid.component';

const DEFAULT_DROPZONE_CONFIG: DropzoneConfigInterface = {
    url: '#',
    maxFilesize: 50,
    // acceptedFiles: '',
    maxFiles: 1,
    addRemoveLinks: true,
    autoQueue: false,
    autoProcessQueue: false,
    uploadMultiple: false,
    headers: {
        'Cache-Control': '',
        'X-Requested-With': '',
    },
    method: 'post',
};

@NgModule({
    // eslint-disable-next-line prettier/prettier
    declarations: [
        SfFormDateComponent,
        SfFormDatetimeComponent,
        SfFormTimeComponent,
        UserFormComponent,
        NavbarComponent,
        LoginComponent,
        DashboardComponent,
        ReloadPageComponent,
        SfFormSelectImagegridComponent,
    ],

    imports: [
        CommonModule,
        FormsModule,
        RouterModule,

        // To be exported
        Ngxh6Module,
        SfFormModule.forRoot({
            customTypes: [
                { prefix: 'date', component: SfFormDateComponent },
                { prefix: 'datetime', component: SfFormDatetimeComponent },
                { prefix: 'time', component: SfFormTimeComponent },
            ],
        }),
        ToastrModule.forRoot({
            positionClass: 'toast-top-center',
            preventDuplicates: true,
            countDuplicates: true,
            resetTimeoutOnDuplicate: true,
            newestOnTop: false,
            enableHtml: false,
            // timeOut: 1000,
        }),
        DropzoneModule,
        MomentModule.forRoot({
            relativeTimeThresholdOptions: {
                s: 60,
                m: 60,
                h: 24,
                d: 7,
                w: 4,
                M: 12,
            },
        }),
    ],
    exports: [
        // Declared
        SfFormDateComponent,
        SfFormDatetimeComponent,
        SfFormTimeComponent,
        NavbarComponent,

        // IMPORTS:
        Ngxh6Module,
        SfFormModule,
        SfFormSelectImagegridComponent,
    ],
    providers: [
        {
            provide: DROPZONE_CONFIG,
            useValue: DEFAULT_DROPZONE_CONFIG,
        },
    ],
})
export class SharedModule {}

<div #ddm="ngbDropdown" ngbDropdown display="dynamic" placement="auto" container="body" dropdownClass="time-dropdown" (openChange)="openChange($event)">
    <div class="input-group">
        <input
            type="time"
            class="form-control"
            #formInput="ngModel"
            [id]="view?.vars?.full_name"
            [(ngModel)]="inputValue"
            [required]="view?.vars?.required || false"
            [maxlength]="view?.vars?.attr?.maxlength"
            [minlength]="view?.vars?.attr?.minlength"
            [pattern]="view?.vars?.attr?.pattern"
            [disabled]="!!view?.vars?.disabled"
            (ngModelChange)="onInputChange()"
            [class.errors]="view?.vars?.errors.length"
        />

        <button class="btn btn-outline-gray no-caret" ngbDropdownToggle type="button" *ngIf="!view.vars.disabled">
            <i class="fa fa-clock"></i>
        </button>
    </div>

    <div ngbDropdownMenu *ngIf="!view.vars.disabled">
        <div class="inner">
            <div class="top p-2 px-3 border-bottom-1 d-flex d-lg-none">
                <span class="text-muted m-0">{{ view.vars.label || view.vars.name | ucfirst }}</span>
                <div class="ms-auto btn-close" (click)="ddm.close()"></div>
            </div>

            <div class="mx-2 d-flex align-items-center justify-content-center">
                <ngb-timepicker [(ngModel)]="inputValue" [minuteStep]="5" [spinners]="true" [meridian]="true"></ngb-timepicker>
            </div>

            <div class="d-flex actions p-2">
                <div class="btn btn-outline-danger" (click)="clear()">Clear</div>
                <div class="ms-auto btn btn-outline-gray" (click)="selectNow()">Now</div>
                <div class="ms-2 btn btn-gray" (click)="ddm.close()">Done</div>
            </div>
        </div>
    </div>
</div>
